import React from 'react'

import { Button, Table } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faFile, faTrash } from '@fortawesome/free-solid-svg-icons'

const App = (props) => {
    const {
        listParams, listItems,
        setShowModalShowResultPefindo,
        setSelectedShowResultPefindo,
        setShowModalProcessedPefindo,
        setSelectedProcessedPefindo,
        setShowModalDeletedPefindo,
        setSelectedDeletedPefindo
    } = props

    const onShowResultClicked = (item) => {
        setSelectedShowResultPefindo({ ...item })
        setShowModalShowResultPefindo(true)
    }

    const onProcessClicked = (item) => {
        setSelectedProcessedPefindo({ ...item })
        setShowModalProcessedPefindo(true)
    }

    const onDeleteClicked = (item) => {
        setSelectedDeletedPefindo({ ...item })
        setShowModalDeletedPefindo(true)
    }

    return (
        <Table responsive striped bordered hover>
            <thead>
                <tr>
                    <th style={{ width: '1%' }}>#</th>
                    <th>File</th>
                    <th style={{ width: '30%' }}>Informasi</th>
                    <th style={{ width: '80px' }} className="text-center">Hasil</th>
                    <th style={{ width: '100px' }}>Diproses</th>
                    <th style={{ width: '100px' }}>Dibuat</th>
                    <th style={{ width: '1%' }} colSpan={3} className="text-center">Tindakan</th>
                </tr>
            </thead>
            <tbody>
                {listItems?.map((item, index) => (
                    <tr key={item.uuid}>
                        <td>{((((listParams?.page || 1) - 1)) * (listParams?.limit || 0)) + (index + 1)}</td>
                        <td><pre style={{
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                        }}>{item.file_name || item.uuid}</pre></td>
                        <td style={{ whiteSpace: 'break-spaces' }}>
                            {
                                item && item.pefindo_detail
                                    ? item.pefindo_detail.map((detail, index) => {
                                        return `${index + 1}. ${detail.borrower_name}`
                                    }).join('\n')
                                    : null
                            }
                        </td>
                        <td style={{ width: '115px' }} className="text-center">
                            {
                                (item.processed_at) &&
                                (<Button size="sm" variant="link" className="text-decoration-none"
                                    onClick={() => onShowResultClicked(item)}>
                                    <FontAwesomeIcon icon={faFile} size="lg" />
                                    <p className="m-1 mb-0">Lihat Hasil</p>
                                </Button>)
                            }
                        </td>
                        <td>{item.processed_at}</td>
                        <td>{item.created_at}</td>
                        <td className="text-center">
                            {
                                (!item.processed_at || process.env.REACT_APP_MODE_ENV !== 'production') &&
                                (<Button size="sm" variant="link" className="text-decoration-none"
                                    onClick={() => onProcessClicked(item)}>
                                    <FontAwesomeIcon icon={faCog} size="lg" />
                                    <p className="m-1 mb-0">Proses</p>
                                </Button>)
                            }
                        </td>
                        <td className="text-center">
                            <Button size="sm" variant="link" className="text-decoration-none"
                                onClick={() => onDeleteClicked(item)}>
                                <FontAwesomeIcon icon={faTrash} size="lg" className="text-danger" />
                                <p className="m-1 mb-0">Hapus</p>
                            </Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table >
    )
}

export default App
