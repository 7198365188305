import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { useAnalyticsPushEvent } from '../../analytics'
import PefindoDeleteModal from './PefindoDeleteModal'
import PefindoTableData from './PefindoTableData'

const App = forwardRef(({ mergePefindo, pefindos, fetchFilesPefindo, processing }, ref) => {
    const pushEvent = useAnalyticsPushEvent()

    const handlePreviewUploaded = (item) => {
        pushEvent('Preview Uploaded File', {
            clickedItem: item
        })
        window.open(item.file_url)
    }

    // Delete pefindo.
    const [showModalDeletedPefindo, setShowModalDeletedPefindo] = useState(false)
    const [selectedDeletedPefindo, setSelectedDeletedPefindo] = useState(null)

    // Passwords.
    const [pefindoPasswords, setPefindoPasswords] = useState({})
    const handleChangePefindoPassword = (uuid, value) => {
        const newPefindoPasswords = { ...pefindoPasswords }
        newPefindoPasswords[uuid] = value
        setPefindoPasswords(newPefindoPasswords)
    }

    const onPefindoDeleted = (deleted) => {
        fetchFilesPefindo(mergePefindo)

        // Adds to amplitude.
        pushEvent('Pefindo File deleted', { clickedItem: deleted })
    }

    // Inits.
    useEffect(() => {
        const maps = {}
        pefindos.forEach(x => {
            maps[x.uuid] = x.password || ''
        })
        setPefindoPasswords(maps)
    }, [pefindos])

    useImperativeHandle(ref, () => ({
        getPefindoPasswords: () => { return pefindoPasswords }
    }))

    return (
        <>
            <PefindoTableData
                mergePefindo={mergePefindo} pefindos={pefindos}
                pefindoPasswords={pefindoPasswords} handleChangePefindoPassword={handleChangePefindoPassword}
                processing={processing} handlePreviewUploaded={handlePreviewUploaded}
                setShowModalDeletedPefindo={setShowModalDeletedPefindo}
                setSelectedDeletedPefindo={setSelectedDeletedPefindo}
            />

            <PefindoDeleteModal
                showModal={showModalDeletedPefindo}
                setShowModal={setShowModalDeletedPefindo}
                selectedItem={selectedDeletedPefindo}
                onFileDeleted={onPefindoDeleted}>
            </PefindoDeleteModal>
        </>
    )
})

export default App
