import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import AddApplicantVerification from '../components/Verification/Applicant/AddApplicantForm'
import ApplicantVerification from '../components/Verification/Applicant/Dashboard'
import ConsumptiveStatementDashboard from '../components/ConsumptiveStatement/Dashboard'
import DetailApplicantInformation from '../components/Verification/Applicant/DetailApplicant'
import UsageReportDashboard from '../components/UsageReport/Dashboard'
import Footer from '../components/Footer'
import Slik from '../components/Slik/Dashboard'
import Pefindo from '../components/Pefindo/Dashboard'
import StatementDashboard from '../components/Statement/Dashboard'
import StructureNav from '../components/Structure/Nav'
import DashboardProfile from '../components/Profile'
import AccountInfo from '../components/Profile/AccountInfo'
import ChangePassword from '../components/Profile/ChangePassword'
import TwoFactorAuthentication from '../components/Profile/TwoFactorAuthentication'
import Error404 from './error/Error404'

import './App.css'

function App () {
    return (
        <>
            <StructureNav />
            <Container style={{ marginBottom: '50px' }}>
                <Routes>
                    <Route exact path="/" element={<Navigate to="/statement" />}></Route>
                    <Route path="/statement/*" element={<StatementDashboard />} />
                    <Route path="/consumptive-statement/*" element={<ConsumptiveStatementDashboard />} />
                    <Route path="/slik/*" element={<Slik />} />
                    <Route path="/pefindo/*" element={<Pefindo />} />
                    <Route path="/applicant-verification/*" element={<ApplicantVerification />} />
                    <Route path="/applicant-verification/new" element={<AddApplicantVerification />} />
                    <Route path="/applicant-verification/information/:uuid" element={<DetailApplicantInformation />} />
                    <Route path="/usage-report/*" element={<UsageReportDashboard />} />
                    <Route path="/profile" element={<DashboardProfile />}>
                        <Route index element={<Navigate to="account-info" />} />
                        <Route path="account-info" element={<AccountInfo />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="two-factor-authentication" element={<TwoFactorAuthentication />} />
                    </Route>
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </Container>
            <Footer />
        </>
    )
}

export default App
