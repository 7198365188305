import React, { useContext, useEffect, useRef, useState } from 'react'

import Axios from 'axios'
import { Card, ProgressBar, Spinner, Tab, Tabs } from 'react-bootstrap'

import AppContext from '../../utils/context'
import StatementUpload from '../Shared/Upload'
import CustomModal from '../Shared/CustomModal'
import StatementContainer from './StatementContainer'
import { useAnalyticsPushEvent } from '../../analytics'
import Step3 from './MergeStatementProcessStep3BuyerModal'
import Step1 from './MergeStatementProcessStep1WinDresModal'
import Step2 from './MergeStatementProcessStep2SupplierModal'
import {
    GetStatementList, PostMergeStatementMerge, PostStatementPreprocess, PostStatementProcess, PostStatementUpload, PutMergeStatementDashboardStatus
} from '../../utils/api'

function App ({ showModal, setShowModal, selectedItem, onProcessCompleted }) {
    const { pushNotification } = useContext(AppContext)
    const pushEvent = useAnalyticsPushEvent()
    const [loading, setLoading] = useState(false)

    // Computed values.
    const hasNotBeenProcessed = () =>
        (selectedItem && !selectedItem.processed_at) ||
        process.env.REACT_APP_MODE_ENV !== 'production'

    // Tabs.
    const tabSelected = (key) => {
        // Adds to amplitude.
        pushEvent(`Change Statement Folder to ${key}`, { clickedItem: selected })
    }

    // Gets detail statement.
    const [detailStatements, setDetailStatements] = useState([])
    async function fetchFilesStatement (item) {
        if (!item) return

        try {
            setLoading(true)
            const params = { merge_uuid: item.uuid }
            const { data: responseData } = await Axios.get(GetStatementList(), { params })
            setDetailStatements(responseData?.data)
        } catch (err) {
            pushNotification('error', null, err)
        } finally {
            setLoading(false)
        }
    }

    // Upload.
    const [uploadUrl, setUploadUrl] = useState(null)
    const uploadFilePicked = (file, filePickClicked) => {
        pushEvent('Statement File picked in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            filePickClicked
        })
    }
    const uploadFileUploaded = (file) => {
        fetchFilesStatement(selectedItem)

        pushEvent('Statement File uploaded in Folder', {
            file: {
                name: file.name,
                size: file.size,
                type: file.type
            },
            folder: {
                uuid: selectedItem.uuid
            }
        })
    }

    // Processing filters && process.env.REACT_APP_MODE_ENV !== 'production'.
    const listRef = useRef()
    const step1Ref = useRef()
    const step2Ref = useRef()
    const step3Ref = useRef()

    // Process statement.
    const [processing, setProcessing] = useState(false)
    const [processingMax, setProcessingMax] = useState(100)
    const [processingProgress, setProcessingProgress] = useState(100)
    const [processingPPercentage, setProcessingPPercentage] = useState(100)
    const [selected, setSelected] = useState(null)
    const [uploading, setUploading] = useState(false)

    // Adds sleep.
    const sleep = function (ms = 200) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    // 1 page estimated take 6 seconds.
    //      then 6 sec * 1000 ms / 200 sleep ms
    const ONE_UNIT_COUNTER = 1000 / 200
    const PER_STATEMENT_PER_PAGE_MAX_COUNTER = 6 * ONE_UNIT_COUNTER
    const AVERAGE_PAGE_NUMBER_PER_STATEMENT_PERIOD = 10
    const PER_STATEMENT_MAX_COUNTER = PER_STATEMENT_PER_PAGE_MAX_COUNTER * AVERAGE_PAGE_NUMBER_PER_STATEMENT_PERIOD

    const executeBackgroundProgress = async ({
        progressReference,
        maxCounter
    }) => {
        // eslint-disable-next-line no-unmodified-loop-condition
        while (progressReference.isBackgroundProcessActive &&
            progressReference.currentBackgroundProcessCounter <= maxCounter) {
            // eslint-disable-next-line no-await-in-loop
            await sleep()

            progressReference.currentBackgroundProcessCounter += 1
            progressReference.progress += 1
            setProcessingProgress(progressReference.progress)
            setProcessingPPercentage(Math.floor((progressReference.progress * 100) / progressReference.maxProgressCounter))
        }
    }

    const executeStatement = async ({
        currentStatement,
        statementPasswords,
        windowDressingFilters,
        supplierFilters,
        buyerFilters,
        progressReference
    }) => {
        try {
            const password = statementPasswords?.[currentStatement.uuid]

            // Preprocess.
            const url0 = PostStatementPreprocess(currentStatement.uuid)
            const preprocessPayload = {
                password
            }
            await Axios.post(url0, preprocessPayload)

            // Process.
            const url = PostStatementProcess(currentStatement.uuid)
            const processPayload = {
                password,
                windowDressingFilters,
                supplierFilters,
                buyerFilters
            }
            const { data: responseData } = await Axios.post(url, processPayload)
            if (responseData?.data?.success) {
                currentStatement.processed_at = true
            }

            return responseData
        } catch (err) {
            if (!['STPPS45', 'STPPS46'].includes(err?.response?.data?.error_code)) {
                pushNotification('error', null, err)
            } else {
                listRef.current.setStatementErrorFilesMessageTable({
                    uuid: currentStatement.uuid,
                    message: err?.response?.data?.message,
                    errorCode: err?.response?.data?.error_code
                })
            }
        } finally {
            progressReference.isBackgroundProcessActive = false
        }
    }

    const executeMerging = async ({
        windowDressingFilters,
        supplierFilters,
        buyerFilters,
        progressReference
    }) => {
        try {
            const url = PostMergeStatementMerge()
            const payload = {
                uuid: selected.uuid,
                windowDressingFilters,
                supplierFilters,
                buyerFilters
            }
            const { data: responseData } = await Axios.post(url, payload)
            return responseData
        } catch (_) {
            // Ignores.
        } finally {
            progressReference.isBackgroundProcessActive = false
        }
    }

    const handleAction = async () => {
        if (!detailStatements?.length) return

        async function fetchData () {
            try {
                const progressReference = {
                    isBackgroundProcessActive: true,
                    progress: 0,
                    currentBackgroundProcessCounter: 0,
                    maxProgressCounter: 100
                }

                // Defines important variables.
                const listStatements = detailStatements
                const windowDressingFilters = (step1Ref.current.getProcessingFilters() || []).filter(x => x?.trim())
                const supplierFilters = (step2Ref.current.getProcessingFilters() || []).filter(x => x?.trim())
                const buyerFilters = (step3Ref.current.getProcessingFilters() || []).filter(x => x?.trim())
                const statementPasswords = listRef.current.getStatementPasswords()

                // Resets values globally before starting.
                listRef.current.setStatementErrorFilesMessageTable(null, true)
                // +1 for merge process and add one unit counter for making stuck at 99%
                progressReference.maxProgressCounter = ((listStatements.length + 1) * PER_STATEMENT_MAX_COUNTER) + ONE_UNIT_COUNTER
                setProcessingMax(progressReference.maxProgressCounter)
                setProcessingProgress(0)
                setProcessingPPercentage(0)
                setProcessing(true)

                // Executes sequentially.
                const results = []
                await listStatements.reduce(async (p, value) => {
                    await p

                    // Execute statement process.
                    progressReference.isBackgroundProcessActive = true
                    progressReference.currentBackgroundProcessCounter = 0
                    const [result] = await Promise.all([
                        executeStatement({
                            currentStatement: value,
                            statementPasswords,
                            windowDressingFilters,
                            supplierFilters,
                            buyerFilters,
                            progressReference
                        }),
                        executeBackgroundProgress({
                            progressReference,
                            maxCounter: PER_STATEMENT_MAX_COUNTER
                        })
                    ])

                    // Advances the progress at the rest, if not reach maximum counter
                    const leftover = (PER_STATEMENT_MAX_COUNTER - progressReference.currentBackgroundProcessCounter)
                    progressReference.progress += leftover
                    setProcessingProgress(progressReference.progress)
                    setProcessingPPercentage(Math.floor((progressReference.progress * 100) / progressReference.maxProgressCounter))

                    results.push(result)
                    return result
                }, Promise.resolve())

                // Executes merging.
                progressReference.isBackgroundProcessActive = true
                progressReference.currentBackgroundProcessCounter = 0
                const [mergingResponseData] = await Promise.all([
                    executeMerging({
                        windowDressingFilters,
                        supplierFilters,
                        buyerFilters,
                        progressReference
                    }),
                    executeBackgroundProgress({
                        progressReference,
                        maxCounter: PER_STATEMENT_MAX_COUNTER
                    })
                ])

                // Post Action each step.
                step1Ref.current.postAction(windowDressingFilters)
                step2Ref.current.postAction(supplierFilters)
                step3Ref.current.postAction(buyerFilters)

                // Resets values globally after ending.
                setProcessingProgress(progressReference.maxProgressCounter)
                setProcessingPPercentage(100)

                // Adds sleep to visually show the progress of 100.
                await sleep(1500)

                // Resets values globally after ending.
                setProcessing(false)
                setShowModal(false)

                if (mergingResponseData?.message) {
                    pushNotification('success', mergingResponseData.message)
                }

                // Adds to amplitude.
                pushEvent('Statement Folder processed', {
                    clickedItem: selected,
                    filter: {
                        windress: windowDressingFilters,
                        suppliers: supplierFilters,
                        buyers: buyerFilters
                    }
                })

                if (onProcessCompleted && typeof onProcessCompleted === 'function') {
                    onProcessCompleted()
                }
            } catch (err) {
                pushNotification('error', null, err)
                setProcessing(false)
            }
        }

        try {
            await Axios.put(PutMergeStatementDashboardStatus(selectedItem.uuid))
            await fetchData()
        } catch (err) {
            pushNotification('error', null, err)
        }
    }

    // Inits.
    useEffect(() => {
        // Resets first.
        setUploadUrl(null)
        setDetailStatements([])

        if (!selectedItem) return

        fetchFilesStatement(selectedItem)
        setUploadUrl(PostStatementUpload(selectedItem.uuid))
        setSelected(selectedItem)

        // Adds to amplitude.
        pushEvent('Preprocess Statement Folder', {
            folder: {
                uuid: selectedItem.uuid
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem])

    return (
        <CustomModal
            size="xl"
            show={showModal}
            handleClose={() => setShowModal(false)}
            title="Proses Rekening Koran (UMKM)"
            primaryButtonText={hasNotBeenProcessed() ? 'Proses' : ''}
            primaryButtonAction={handleAction}
            primaryButtonVariant="primary"
            primaryButtonDisabled={!detailStatements?.length}
            secondaryButtonText="Tutup"
            secondaryButtonDisabled={processing || uploading}
            loading={processing || uploading}
            keyboard={!processing && !uploading}
            closeButton={!processing && !uploading}>
            { loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            { !loading && <>
                {
                    (detailStatements?.length > 10 && processing) &&
                            (<ProgressBar animated now={processingProgress} max={processingMax}
                                label={`${processingPPercentage}%`} className="mb-3" />)
                }
                <Card>
                    <Card.Header>Daftar Rekening Koran</Card.Header>
                    <Card.Body>
                        {
                            !processing &&
                                (<StatementUpload
                                    uploadUrl={uploadUrl}
                                    filePicked={uploadFilePicked}
                                    fileUploaded={uploadFileUploaded}
                                    setUploading={setUploading}
                                    params={{ type: 'sme' }}
                                />)
                        }
                        <StatementContainer
                            ref={listRef}
                            mergeStatement={selectedItem}
                            statements={detailStatements}
                            fetchFilesStatement={fetchFilesStatement}
                            processing={processing || uploading} />
                    </Card.Body>
                </Card>
            </>}
            {
                hasNotBeenProcessed() &&
                (<Card className="mt-3">
                    <Card.Header>Kata Kunci</Card.Header>
                    <Card.Body>
                        <Tabs
                            defaultActiveKey="window-dressing" onSelect={tabSelected}
                            className="mb-3">
                            <Tab eventKey="window-dressing" title="Window Dressing">
                                <Step1 ref={step1Ref} selected={selected} processing={processing}></Step1>
                            </Tab>
                            <Tab eventKey="supplier" title="Supplier">
                                <Step2 ref={step2Ref} selected={selected} processing={processing}></Step2>
                            </Tab>
                            <Tab eventKey="buyer" title="Buyer">
                                <Step3 ref={step3Ref} selected={selected} processing={processing}></Step3>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>)
            }
            {processing && (<ProgressBar animated now={processingProgress} max={processingMax}
                label={`${processingPPercentage}%`} className="mt-3" />)}

        </CustomModal>
    )
}

export default App
